export const MonthList = [
  {
    text: "Janvier",
    value: 1,
  },
  {
    text: "Février",
    value: 2,
  },
  {
    text: "Mars",
    value: 3,
  },
  {
    text: "Avril",
    value: 4,
  },
  {
    text: "Mai",
    value: 5,
  },
  {
    text: "Juin",
    value: 6,
  },
  {
    text: "Juillet",
    value: 7,
  },
  {
    text: "Août",
    value: 8,
  },
  {
    text: "Septembre",
    value: 9,
  },
  {
    text: "Octobre",
    value: 10,
  },
  {
    text: "Novembre",
    value: 11,
  },
  {
    text: "Décembre",
    value: 12,
  },
];
